  /* Scrollbar Track */
  .custom-scrollbar::-webkit-scrollbar {
    width: 4.2px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    @apply bg-bg-3;
    border-radius: 105px;
  }
  
  /* Scrollbar Thumb */
  .custom-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-white;
    border-radius: 105px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
  }
  