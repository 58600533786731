@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --creating-animation-duration: 120s;
}

body {
  @apply font-workSans bg-bg;
}

@layer utilities {
  .text-h1 {
    @apply text-head1 font-workSans;
  }

  .text-h2 {
    @apply text-head2 font-workSans;
  }

  .text-h3 {
    @apply text-head3 font-workSans;
  }

  .text-h4 {
    @apply text-head4 font-workSans;
  }

  .text-h5 {
    @apply text-head5 font-workSans;
  }

  .text-h6 {
    @apply text-head6 font-workSans;
  }
  .text-h7 {
    @apply text-head7 font-workSans;
  }

  .text-bodyLg {
    @apply text-bodyLarge font-workSans;
  }

  .text-bodyMd {
    @apply text-bodyMedium font-workSans;
  }

  .text-bodySm {
    @apply text-bodySmall font-workSans;
  }
  .text-headLine {
    @apply text-headline font-workSans;
    @apply uppercase;
  }

  .bg-gradient-default-to-r {
    @apply bg-gradient-to-r to-gray-default;
  }

  .text-overflow {
    @apply whitespace-nowrap text-ellipsis overflow-hidden flex-1;
  }
  .text-transparent {
    color: transparent;
  }

  .container-fluid {
    @apply w-full px-5 3xl:mx-auto 3xl:max-w-[1980px];
  }

  .box {
    width: 100px;
    height: 100px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: green;
    font-weight: 600;
    color: white;
  }

  .container-fluid-header {
    @apply w-full px-5 3xl:mx-auto 3xl:max-w-[1980px];
  }

  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  .btn-primary-animation {
    @apply transition-all hover:bg-purple;
  }

  .btn-secondary-animation {
    @apply transition-all hover:bg-pink;
  }

  .btn-outline-animation {
    @apply transition-all hover:border-white;
  }

  .btn-warning-animation {
    @apply transition-all hover:bg-orange-light;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }
}

@layer components {
  .grid-skeleton {
    @apply relative overflow-hidden;
    z-index: 2;
  }

  .grid-skeleton:after {
    @apply absolute top-0 right-0 bottom-0 left-0;
    border-radius: 5px;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(37, 37, 37, 0) 0, rgba(37, 37, 37, 0.2) 20%, rgba(37, 37, 37, 0.5) 60%, rgba(37, 37, 37, 0));
    animation: shimmer 2s infinite;
    content: "";
    z-index: 3;
  }

  .input-range__slider {
    @apply appearance-none bg-white rounded-full border-4 border-white;
    @apply cursor-pointer block h-2 w-2;
    @apply outline-none absolute top-1/2;
    margin-left: -9px;
    margin-top: -9px;
    box-sizing: content-box;
  }

  .input-range--disabled .input-range__slider {
    @apply bg-black border border-black shadow-none transform-none;
  }

  .input-range__track {
    @apply bg-border rounded-md cursor-pointer;
    @apply block h-[2px] relative;
  }

  .input-range--disabled .input-range__track {
    @apply bg-black;
  }

  .input-range__track--background {
    @apply absolute left-0 right-0 top-0;
  }

  .input-range__track--active {
    @apply bg-green h-[2px] rounded-md;
  }

  .input-range {
    @apply relative w-full h-[2px];
  }

  .btn {
    @apply flex gap-1.5 py-[9.5px] px-4 items-center justify-center;
    @apply font-workSans text-head6 text-bg rounded-lg;
    @apply disabled:!bg-bg-3  disabled:cursor-default disabled:pointer-events-none disabled:text-grey;
  }

  .btn.btn-primary {
    @apply bg-green;
    @apply btn-primary-animation;
  }

  .btn.btn-secondary {
    @apply bg-white;
    @apply btn-secondary-animation;
  }

  .btn.btn-outline {
    @apply text-white bg-bg border border-border;
    @apply btn-outline-animation;
  }

  .btn.btn-warning {
    @apply bg-orange;
    @apply btn-warning-animation;
  }

  .btn.btn-google {
    @apply bg-white;
    @apply gap-2.5;
  }

  .btn.btn-big {
    @apply text-buttonDefault gap-2.5 py-[13px] px-6;
  }

  .btn.btn-secondary.no-bg {
    background-color: transparent;
  }

  .btn.no-bg {
    background-color: transparent;
  }

  .btn.btn-sm {
    @apply px-[10px];
    /* @apply text-headlineMd; */
  }

  .btn-icon {
    background: transparent;
    @apply p-0;
    @apply w-10 h-10 rounded-md flex-center;
    @apply transition-none hover:gap-0 hover:px-0;
    @apply border border-border border-opacity-10;
  }

  .tab {
    @apply w-full lg:w-auto;
    @apply flex items-center;
  }

  .tab li {
    @apply block flex-1;
  }

  .tab li span {
    @apply block text-bodyMd cursor-pointer text-grey text-center;
    @apply px-2.5 py-[24.5px];
    @apply whitespace-nowrap;
  }

  .tab li span:hover {
    @apply text-white;
  }

  .tab li span.active {
    @apply text-green;
  }

  .tab.icon {
    background: transparent;
    @apply inline-flex border border-border rounded-sm;
  }

  .tab.icon.vertical {
    @apply block;
  }

  .tab.icon li {
    @apply border-l border-l-border border-r-0 first:border-none text-black;
  }

  .tab.icon li span {
    background: transparent;
    @apply p-2 text-grey rounded-none;
  }

  .tab.icon li span:hover {
    background: initial;
    @apply text-white;
  }

  .tab.icon li span.active {
    @apply text-bg bg-white rounded-sm after:hidden;
  }

  .tab.icon.link li span.active {
    background: initial;
    @apply text-grey;
  }

  .icon-btn {
    @apply h-10 py-4 px-2.5 rounded-lg cursor-pointer gap-2.5;
  }

  .tab-button {
    @apply flex items-center text-center gap-x-0.5 px-2 pt-2.5 pb-3 rounded text-h6 text-bg bg-white;
  }

  .tab-button.secondary {
    margin: -1px 0;
    @apply text-white;
    @apply px-8 py-7;
    @apply bg-bg border border-border border-b-white rounded-none;
  }

  .input {
    background: transparent;
    @apply w-full text-white outline-none text-head6 placeholder-grey;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
  }

  .input:focus {
    background: transparent;
  }
  .input:disabled {
    @apply text-grey;
  }

  .input-container:has(.input:focus) {
    background: rgba(255, 255, 255, 0.1);
  }

  .input-container:has(input:not(:placeholder-shown)) {
    background: rgba(255, 255, 255, 0.1);
  }

  .input-container:has(textarea:not(:placeholder-shown)) {
    background: rgba(255, 255, 255, 0.1);
  }

  @keyframes progressAnimation {
    0% {
      width: 0;
    }
    100% {
      width: 99%;
    }
  }

  .animate-progress {
    animation: progressAnimation var(--creating-animation-duration) forwards;
  }

  .animate-image-upload {
    animation: progressAnimation 10s forwards;
  }

  .dot-flashing {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    @apply text-border bg-border;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.35s;
  }
  .dot-flashing::before,
  .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -12px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    @apply text-border bg-border;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 12px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    @apply text-border bg-border;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0.7s;
  }

  @keyframes dot-flashing {
    0% {
      @apply bg-white;
    }
    50%,
    100% {
      @apply bg-border;
    }
  }

  .checkbox {
    @apply flex items-center cursor-pointer;
  }

  .checkbox > input[type="checkbox"] {
    @apply hidden;
  }

  .checkbox > span {
    @apply bg-bg-3 w-4 h-4 rounded-sm border border-green;
  }

  /* .checkbox > input:checked + span {
    @apply bg-green;
  } */

  /*

  .header-btn {
    @apply p-[21px];
    @apply items-center justify-center;
    @apply text-headlineMd font-bigShoulderDisplay text-white;
  }

  .header-btn:hover {
    @apply bg-bg-light;
  }

  .title-gradient {
    @apply inline-flex gap-2.5 items-center uppercase;
    @apply px-5 py-2;
    @apply text-headlineMd font-bigShoulderDisplay text-white rounded-md;
    background-color: transparent;
    background-image: linear-gradient(90deg, transparent 0%, #252525 100%);
  }

  .title-gradient:hover {
    background-image: linear-gradient(90deg, #252525 0%, transparent 100%);
  }




  .radio {
    @apply flex gap-2 items-center cursor-pointer;
  }

  .radio > input[type="radio"] {
    @apply hidden;
  }

  .radio > span {
    @apply bg-gray w-4 h-4 rounded-full;
  }

  .radio > input:checked + span {
    @apply bg-white;
  }

  .input-range__slider {
    @apply appearance-none bg-white rounded-full border-4 border-black;
    @apply cursor-pointer block h-2.5 w-2.5;
    @apply outline-none absolute top-1/2;
    margin-left: -9px;
    margin-top: -14px;
    box-sizing: content-box;
  }

  .input-range--disabled .input-range__slider {
    @apply bg-black border border-black shadow-none transform-none;
  }

  .input-range__track {
    @apply bg-gray rounded-md cursor-pointer;
    @apply block h-2.5 relative;
  }

  .input-range--disabled .input-range__track {
    @apply bg-black;
  }

  .input-range__track--background {
    @apply absolute left-0 right-0 top-0;
  }

  .input-range__track--active {
    @apply bg-white h-2.5 rounded-md;
  }

  .input-range {
    @apply relative w-full h-2.5;
  } */

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  .toast {
    background: #252525 !important;
  }

  .toast-process {
    top: 0;
    background: white !important;
    height: 2px !important;
  }

  .skeleton-box {
    position: relative;
    overflow: hidden;
  }

  .skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, transparent 0%, rgba(71, 71, 71, 0.4) 50%, transparent 100%);
    animation: pro-shimmer 2s infinite; /* Adjust the animation speed */
    content: "";
  }
  @keyframes pro-shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
